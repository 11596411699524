import styled from 'styled-components'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import pluralize from 'pluralize'
import Card from '../../../shared/Card'
import BackLink from '../../backLink/BackLink'
import { ROUTES } from '../../../../constants/routes'
import ComparisonDetails, { TComparisonDetailsRow } from '../../comparisonDetails/ComparisonDetails'
import { ReactComponent as DatabaseIcon } from '../../../../assets/Database.svg'
import { ReactComponent as KeyIcon } from '../../../../assets/Key.svg'
import Table from './Table'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import { useCompareVariablesQuery } from '../../../../queries/useCompareVariablesQuery'
import Spinner from '../../../shared/Spinner'
import Link from '../../../shared/Link'
import { useOpenDataset } from '../../../../hooks/useOpenDataset'
import { useDatasetData } from '../hooks/useDatasetData'
import IconButton from '../../../shared/IconButton'
import { ReactComponent as EditIcon } from '../../../../assets/Edit.svg'
import AdvancedPagination from '../../advancedPagination/components/AdvancedPagination'
import { PaginationProvider, usePaginationContext } from '../../../../hooks/usePagination'

const WrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CardStyled = styled(Card)`
  padding: 0;
  min-width: 1136px;
  max-width: min-content;
  margin: auto;
  height: 100%;
  background: transparent;
  box-shadow: none;
`

const HeaderStickyBarWrapper = styled.div`
  position: sticky;
  z-index: 10001;
  width: 100%;
  top: 60px;
  margin-top: -20px;
`

const HeaderStickyBar = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 20px 20px 0;
  border-radius: 8px;
  margin-top: -5px;
`

const TitleStyled = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 8px;
  display: block;
`

const DatasetNameStyled = styled.span`
  font-weight: 500;
`

const SpinnerStyled = styled(Spinner)`
  margin-top: 20px;
`

const NavigationWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`

const BlueGap = styled.div`
  width: 100%;
  height: 25px;
  background: ${({ theme }) => theme.colors.blue.light};
`

const useFetchComparedVariables = () => {
  const { comparedKeywords } = useKeywordsStateContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const payload = comparedKeywords.map(({ dataset, ...rest }) => rest)
  const { page, startCount, rowsPerPage } = usePaginationContext()
  return useCompareVariablesQuery(payload, false, true, startCount, rowsPerPage, page)
}

const useEmptyDataHandler = () => {
  const { comparedKeywords } = useKeywordsStateContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!comparedKeywords.length) {
      navigate(ROUTES.DATASETS)
    }
  }, [comparedKeywords])
}

const useDetailsRows = (): TComparisonDetailsRow[] => {
  const dataset = useDatasetData()
  const openDataset = useOpenDataset(dataset?.id)
  const keywords = useKeywordsStateContext().comparedKeywords.map(({ name }) => name)
  const { state } = useLocation()

  return [
    {
      icon: <KeyIcon />,
      name: `Search term${state?.isKeywords ? '(s)' : ''}`,
      items: keywords
    },
    {
      icon: <DatabaseIcon />,
      name: 'Dataset',
      items: (
        <>
          {dataset?.name} -{' '}
          <Link sx={{ fontWeight: 400 }} to={openDataset.to} state={openDataset.state}>
            {dataset?.shortName}
          </Link>
        </>
      )
    }
  ]
}

const VariablesComparison = () => {
  const { comparedKeywords } = useKeywordsStateContext()
  const { data } = useFetchComparedVariables()
  useEmptyDataHandler()
  const navigate = useNavigate()
  const rows = useDetailsRows()
  const dataset = useDatasetData()

  // eslint-disable-next-line no-return-assign
  const variables = comparedKeywords.reduce((acc, curr) => (acc += curr.variablesId.length), 0)
  const { state } = useLocation()
  return (
    <WrapperStyled>
      <CardStyled>
        <HeaderStickyBarWrapper>
          <BlueGap />
          <HeaderStickyBar>
            <NavigationWrapStyled>
              <BackLink link={ROUTES.DATASETS} pageName="main search results" />
              <IconButton
                onClick={() => navigate(ROUTES.DATASETS)}
                sx={{
                  display: 'flex',
                  gap: '8px',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '18.75px'
                }}
              >
                <EditIcon />
                Edit search
              </IconButton>
            </NavigationWrapStyled>
            {data && (
              <TitleStyled>
                {`Your search for the selected term${state?.isKeywords ? '(s)' : ''} returned`}{' '}
                {`${pluralize('variable', variables, true)}`} for the <DatasetNameStyled>{dataset?.name}</DatasetNameStyled>.
              </TitleStyled>
            )}
          </HeaderStickyBar>
        </HeaderStickyBarWrapper>
        {data && <ComparisonDetails rows={rows} />}
        <AdvancedPagination />
        {data ? <Table tableData={data} /> : <SpinnerStyled />}
      </CardStyled>
      <AdvancedPagination />
    </WrapperStyled>
  )
}

export default () => {
  const { comparedKeywords } = useKeywordsStateContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const payload = comparedKeywords.map(({ dataset, ...rest }) => rest)
  // eslint-disable-next-line no-return-assign
  const count = payload.reduce((acc, curr) => (acc += curr.variablesId.length), 0)

  return (
    <PaginationProvider rowsPerPage={75} initialRowsCount={count}>
      <VariablesComparison />
    </PaginationProvider>
  )
}
