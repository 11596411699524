import styled from 'styled-components'
import { FC } from 'react'
import { CellProps } from 'react-table'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CheckIcon } from '../../../assets/Check.svg'
import { TRowData } from '../../../definitions'
import { useKeywordsStateContext } from '../../../state/useKeywordsState'
import { ROUTES } from '../../../../../../constants/routes'
import { getParentRow } from '../../../../../../utils/table'

const CheckIconStyled = styled(CheckIcon)`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    fill: ${({ theme }) => theme.colors.blue.dark};
  }
`

const DatasetKeywordsCell: FC<CellProps<TRowData>> = ({ row, rowsById }) => {
  const { setComparedKeywords } = useKeywordsStateContext()
  const parentRow = row.depth ? getParentRow(row, rowsById) : row
  const navigate = useNavigate()
  const handleClick = () => {
    const comparedKeywords = row.original.keywords?.map((keyword) => ({
      name: keyword.name,
      variablesId: keyword.versions.flatMap(({ variablesId }) => variablesId),
      dataset: {
        id: parentRow.original.id as number,
        name: parentRow.original.name,
        shortName: parentRow.original.short_name as string
      }
    }))
    setComparedKeywords(comparedKeywords ?? [])
    navigate(ROUTES.COMPARE_VARIABLES, { state: { isKeywords: true } })
  }

  return <CheckIconStyled onClick={handleClick} onContextMenu={(e: MouseEvent) => e.preventDefault()} />
}

export default DatasetKeywordsCell
