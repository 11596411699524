import { CSSProperties, FC, ReactNode } from 'react'
import styled from 'styled-components'
import { NavLink, NavLinkProps } from 'react-router-dom'

const LinkStyled = styled(NavLink)`
&&{
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.blue.primary};
    cursor: pointer;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    background: transparent;
    &:hover{
        text-decoration: underline;
        background: transparent;
        text-decoration-color: ${({ theme }) => theme.colors.blue.dark};
        color: ${({ theme }) => theme.colors.blue.dark};
    }
}
`

type TProps={
  to:string
  onClick?:()=>void
  sx?:CSSProperties
  title?:string
  state?:any
  children?:ReactNode
} & NavLinkProps

const Link: FC<TProps> = ({
  sx, state, to, onClick, title, children, ...props
}) => (
  <LinkStyled {...props} state={state} to={to} onClick={onClick} style={sx}>{title || children}</LinkStyled>

)

export default Link
