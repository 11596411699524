import { To, useLocation } from 'react-router-dom'
import { ROUTES } from '../../../../constants/routes'

type TData = {
  name: string
  link: To
}

const PAGE_NAME_MAP: Partial<Record<ROUTES, string>> = {
  [ROUTES.DATASETS]: 'Search results',
  [ROUTES.COMPARE_DATASETS]: 'Compare datasets',
  [ROUTES.KEYWORD_DETAILS]: 'Search results',
  [ROUTES.COMPARE_VARIABLES]: 'Search results',
  [ROUTES.COMPARE_VALUES]: 'Search results'
}

export const useBackLink = (): TData => {
  const { state } = useLocation()
  const prevRoute: ROUTES = state?.from ?? ROUTES.DATASETS

  return {
    name: PAGE_NAME_MAP[prevRoute] as string,
    link: state?.from ? (-1 as To) : ROUTES.DATASETS
  }
}
