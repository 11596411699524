import { InputAdornment, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { forwardRef, ReactNode, useId } from 'react'
import styled, { useTheme } from 'styled-components'

const LabelStyled = styled.label`
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.grey.dark};
`

export type TTextFieldProps = {
  startIcon?: ReactNode
  endIcon?: ReactNode
  required?: boolean
} & TextFieldProps

const TextField = forwardRef<HTMLDivElement, TTextFieldProps>(({ label, startIcon, endIcon, sx, required, ...rest }, ref) => {
  const { colors } = useTheme()
  const id = useId()

  return (
    <div>
      {label && <LabelStyled htmlFor={id}>{`${label}${required ? '*' : ''}`}</LabelStyled>}
      <MuiTextField
        {...rest}
        ref={ref}
        id={id}
        sx={{
          '& .MuiFormHelperText-root': {
            color: colors.red.primary,
            fontSize: '14px',
            lineHeight: '16px',
            margin: '4px 0 0'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.grey.dark
          },
          '& .MuiInputBase-root': {
            paddingLeft: startIcon ? '8px' : undefined,
            '& input': {
              paddingLeft: !startIcon ? '8px' : 0
            }
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: colors.blue.primary,
              borderWidth: '1px'
            },
            '&.Mui-error fieldset': {
              borderColor: colors.red.primary,
              borderWidth: '1px'
            }
          },
          input: {
            fontSize: '14px',
            lineHeight: '16px',
            color: colors.black,
            '&::placeholder': {
              color: colors.grey.dark,
              opacity: 1,
              fontWeight: 600
            }
          },
          ...sx
        }}
        InputProps={{
          sx: {
            height: '36px',
            '& .MuiInputBase-input': {
              height: 0
            }
          },
          endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : undefined,
          startAdornment: startIcon ? (
            <InputAdornment disablePointerEvents position="start">
              {startIcon}
            </InputAdornment>
          ) : undefined
        }}
      />
    </div>
  )
})

export default TextField
