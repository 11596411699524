import { FC, useLayoutEffect } from 'react'
import { CellProps } from 'react-table'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { TRowData } from '../definitions'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { getParentRow } from '../../../../utils/table'
import { ROUTES } from '../../../../constants/routes'
import { useDatasetData } from '../hooks/useDatasetData'
import { TDatasetBaseData } from '../../../../definitions/dataset'

const LinkStyled = styled.div`
  && {
    text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
    color: ${({ theme }) => theme.colors.blue.primary};
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    background: transparent;

    &:hover {
      text-decoration: underline;
      background: transparent;
      text-decoration-color: ${({ theme }) => theme.colors.blue.dark};
      color: ${({ theme }) => theme.colors.blue.dark};
    }
  }
`

const VariableCodeCell: FC<CellProps<TRowData>> = ({ row, rowsById, value }) => {
  const { setVariable, clearVariable } = useComparedVariablesContext()
  const { variableId } = row.original as TRowData
  const dataset = useDatasetData() as TDatasetBaseData
  const { keyword } = getParentRow(row, rowsById).original as TRowData
  const navigate = useNavigate()

  useLayoutEffect(() => {
    clearVariable()
  }, [])

  const handleClick = () => {
    setVariable({
      keyword: keyword as string,
      variableId,
      acrossDatasets: false,
      dataset
    })
    navigate(ROUTES.COMPARE_VALUES)
  }

  return (
    <LinkStyled onClick={handleClick} onContextMenu={(e) => e.preventDefault()}>
      {value}
    </LinkStyled>
  )
}

export default VariableCodeCell
