import React, { ChangeEvent, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { Card } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import Pagination from '../../../shared/pagination/Pagination'
import FormSelect from '../../../shared/formControls/FormSelect'
import { usePaginationContext } from '../../../../hooks/usePagination'
import TextField from '../../../shared/TextField'

const options = [
  {
    label: '50',
    value: 50
  },
  {
    label: '75',
    value: 75
  },
  {
    label: '100',
    value: 100
  }
]

const CardStyled = styled(Card)`
  &&& {
    padding: 20px 0;
    min-width: 1136px;
    max-width: min-content;
    margin: auto;
    background: transparent;
    box-shadow: none;

    & > div {
      display: flex;
      justify-content: space-between;
    }
  }
`

const SelectContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Label = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

const SelectWrapStyled = styled.div`
  background: white;
  width: min-content;
`

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const ManualPaginationInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const ManualPaginationInput = styled(TextField)`
  background: white;
  width: 50px;

  & input[type='number'] {
    -moz-appearance: textfield;
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    display: none;
  }
`

function useRowsCount(rowCount: number) {
  const { setRowsPerPage, handleSetPagePerRows } = usePaginationContext()

  useEffect(() => {
    setRowsPerPage(Number(rowCount))
    handleSetPagePerRows(Number(rowCount))
  }, [rowCount])
}

const AdvancedPagination = () => {
  const [, setSearchParams] = useSearchParams()
  const { page, pagesCount, setPage, handleSetPagePerPageRows, manualInputPageValue, setManualInputPageValue } = usePaginationContext()
  const { control, watch } = useForm({
    defaultValues: {
      rowCount: 75
    }
  })

  useRowsCount(watch('rowCount'))

  const handleChangePage = (_: ChangeEvent<unknown>, page: number) => {
    setPage(page)
    setSearchParams({ page: String(page) })
    handleSetPagePerPageRows(page)
  }

  const handleManualChangePage = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setPage(Number(e.target.value))
    }
  }

  const handlePressEnter = (e: React.KeyboardEvent) => {
    const { value } = e.target as HTMLInputElement
    if (e.key === 'Enter' && value) setPage(Number(value))
  }

  const handleChangeManualPageInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && Number(e.target.value) > pagesCount) return
    setManualInputPageValue(e.target.value)
  }

  return (
    <CardStyled>
      <div>
        <PaginationWrapper>
          <Pagination page={page} count={pagesCount} onChange={handleChangePage} />
          <ManualPaginationInputWrapper>
            <Label>Go to page: </Label>
            <ManualPaginationInput
              onChange={handleChangeManualPageInput}
              value={manualInputPageValue}
              onBlur={handleManualChangePage}
              type="number"
              onKeyDown={handlePressEnter}
            />
          </ManualPaginationInputWrapper>
        </PaginationWrapper>

        <SelectContainerStyled>
          <Label>Results per page:</Label>
          <SelectWrapStyled>
            <FormSelect control={control} name="rowCount" options={options} />
          </SelectWrapStyled>
        </SelectContainerStyled>
      </div>
    </CardStyled>
  )
}

export default AdvancedPagination
