import { TRowData } from './definitions'
import { TDatasetVariablesData, TVariable, TVersion } from '../../../definitions/variable'

export const getAllVariables = (data: TDatasetVariablesData[]) =>
  data.flatMap(({ versions }) => versions.flatMap(({ files }) => files.flatMap(({ variables }) => variables)))

const prepareVariables = (versions: TVersion[]): (TVariable & { file?: string; year?: string })[] =>
  versions.flatMap(({ files, year }) =>
    files.flatMap((file) =>
      file.variables.flatMap((variable) => ({
        ...variable,
        file: file.name,
        year
      }))))

const getRowsByVersions = (versions: TVersion[]): TRowData[] =>
  prepareVariables(versions).map((variable) => ({
    year: variable.year,
    file: variable.file,
    varCode: variable.code,
    varDescription: variable.description,
    variableId: variable.id
  }))

export const prepareTableData = (data: TDatasetVariablesData[]): TRowData[] =>
  data
    .filter((data) => data.versions.length)
    .map(({ name, versions, keywordContinues, versionContinues }) => {
      const [firstVar, ...restVars] = getRowsByVersions(versions)
      return {
        keyword: name,
        year: firstVar.year,
        varCode: firstVar.varCode,
        varDescription: firstVar.varDescription,
        file: firstVar.file,
        variableId: firstVar.variableId,
        keywordContinues,
        versionContinues,
        subRows: restVars.map((row) => ({ ...row,
          keyword: name,
          keywordContinues,
          versionContinues }))
      }
    })
