import { FC } from 'react'
import { HeaderProps } from 'react-table'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { TRowData } from '../../../definitions'
import { useKeywordsStateContext } from '../../../state/useKeywordsState'
import { getVariablesIdsFromRows } from '../../../utils'
import { ROUTES } from '../../../../../../constants/routes'
import Tooltip from '../../../../../shared/Tooltip'

const HeaderTextStyled = styled.div`
  text-decoration-color: ${({ theme }) => theme.colors.blue.primary};
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 500;
  line-height: 16px;
  font-size: 14px;
  padding: 0;
  background: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 92px;
  white-space: nowrap;
`

const KeywordLink = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  max-width: inherit;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover{
    text-decoration-color: ${({ theme }) => theme.colors.blue.dark};
    color: ${({ theme }) => theme.colors.blue.dark};
  }
`

const KeywordHeaderCell: FC<HeaderProps<TRowData>> = ({ data, column }) => {
  const { setComparedKeywords } = useKeywordsStateContext()
  const navigate = useNavigate()
  const keyword = column.id

  const handleClick = () => {
    setComparedKeywords([
      {
        name: keyword,
        variablesId: getVariablesIdsFromRows(data, keyword)
      }
    ])
    navigate(ROUTES.KEYWORD_DETAILS)
  }
  const isVersionedKeyword = data
    .map(({ keywords }: any) => {
      const isVersion = keywords?.find(({ name }: any) => name === keyword)?.versions.length
      return Boolean(isVersion)
    })
    .some(Boolean)

  return (
    <Tooltip id={keyword} title={keyword} placement="top">
      {!isVersionedKeyword ? (
        <HeaderTextStyled>{keyword}</HeaderTextStyled>
      ) : (
        <KeywordLink onClick={handleClick} onContextMenu={(e) => e.preventDefault()}>
          {keyword}
        </KeywordLink>
      )}
    </Tooltip>
  )
}

export default KeywordHeaderCell
