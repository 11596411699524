import { useTheme } from 'styled-components'
import { CellProps, Column, useExpanded, useTable } from 'react-table'
import { Table, TableBody, TableHead } from '@mui/material'
import { useMemo } from 'react'
import Hint from '../../../../../shared/hint/Hint'
import Checkbox from '../../../../../shared/Checkbox'
import { COMPARE_DATASETS_HINT } from '../../../constants'
import { useKeywordsByDatasets } from '../../../hooks/useKeywordsByDatasets'
import useKeywordsTableData from '../../../hooks/useKeywordsTableData'
import HeaderRow from './HeaderRow'
import BodyRow from './BodyRow'
import CompareButton from '../../CompareButton'
import NameCell from '../../NameCell'
import { useDatasetSelectContext } from '../../../state/useDatasetSelect'
import { useDatasetSelect } from '../../../hooks/useDatasetSelect'
import KeywordHeaderCell from './KeywordHeaderCell'
import DatasetKeywordCell from './DatasetKeywordCell'
import DatasetKeywordsCell from './DatasetKeywordsCell'
import { useKeywordsStateContext } from '../../../state/useKeywordsState'
import { splitKeywords } from '../../../utils'
import { useDatasetsContext } from '../../../state/useDatasets'

const useKeywords = () => {
  const keywords = useKeywordsByDatasets()
  const { submittedKeywords } = useKeywordsStateContext()

  return useMemo(() => splitKeywords(submittedKeywords).filter(({ name }) => keywords.includes(name)), [keywords])
}

const useColumns = () => {
  const keywords = useKeywords()
  const { datasets, isLoading } = useDatasetsContext()
  const columns: Column<any>[] = [
    {
      Header: 'Dataset Name',
      accessor: 'name',
      Cell: NameCell
    },
    {
      Header: () => {
        const { selectedIds } = useDatasetSelectContext()
        return (
          <>
            <Hint placement="top" maxWidth={320} title={COMPARE_DATASETS_HINT} withDefaultColor />
            <CompareButton sx={{ marginLeft: '8px' }} disabled={!selectedIds.length} text="Compare" />
          </>
        )
      },
      accessor: 'checkbox',
      Cell: ({ row }) => {
        const { isSelected, handleSelect, isDisabled } = useDatasetSelect(row.original.id)
        return row.depth ? null : <Checkbox checked={isSelected} disabled={isDisabled} onChange={handleSelect} />
      }
    },
    {
      Header: 'All',
      accessor: 'allVariables',
      Cell: DatasetKeywordsCell
    },
    ...keywords.map(({ name: keyword }) => ({
      Header: KeywordHeaderCell,
      id: keyword,
      accessor: keyword,
      Cell: (props: CellProps<any>) => <DatasetKeywordCell {...props} keyword={keyword} />
    }))
  ]

  return useMemo(() => columns, [keywords, datasets, isLoading])
}

const KeywordsTable = () => {
  const { colors } = useTheme()
  const data = useKeywordsTableData()
  const columns = useColumns()

  const { getTableProps, prepareRow, headerGroups, getTableBodyProps, rows } = useTable(
    {
      columns,
      data
    },
    useExpanded
  )

  return (
    <Table
      {...getTableProps()}
      sx={{
        width: 'max-content',
        '& .MuiTableCell-root': {
          textAlign: 'center',
          '&:first-of-type': {
            paddingLeft: '20px',
            width: '400px',
            position: 'absolute',
            textAlign: 'start',
            left: '0'
          },
          '&:nth-of-type(2)': {
            width: '140px',
            position: 'absolute',
            left: '400px',
            top: 'auto'
          },
          '&:first-of-type, &:nth-of-type(2)': {
            display: 'flex',
            alignItems: 'center'
          },
          '&:nth-of-type(3)': {
            width: '92px',
            paddingLeft: '16px'
          },
          '&:nth-of-type(n+4)': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: colors.blue.primary,
            width: '92px',
            maxWidth: '92px',
            paddingLeft: '8px',
            paddingRight: '8px'
          }
        }
      }}
    >
      <TableHead
        sx={{
          '& .MuiTableCell-root': {
            border: 'none',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
            padding: '10px 0 8px',
            verticalAlign: 'bottom',
            '&:first-of-type': {
              alignItems: 'end !important'
            },
            '&:nth-of-type(2)': {
              justifyContent: 'center'
            }
          }
        }}
      >
        {headerGroups.map((headerGroup) => (
          <HeaderRow key={headerGroup.getHeaderGroupProps().key} headerGroup={headerGroup} />
        ))}
      </TableHead>
      <TableBody
        {...getTableBodyProps()}
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px 0',
            fontSize: '14px',
            lineHeight: '16px',
            border: 'none',
            '&:nth-of-type(2) .MuiFormControlLabel-root': {
              marginLeft: '75px'
            }
          }
        }}
      >
        {rows.map((row) => {
          prepareRow(row)
          return <BodyRow row={row} key={row.id} />
        })}
      </TableBody>
    </Table>
  )
}

export default () => {
  const { isLoading } = useDatasetsContext()
  if (isLoading) {
    return <></>
  }
  return <KeywordsTable />
}
