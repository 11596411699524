import { TFilterShortName } from './definitions'

export const SEARCH_HINT = 'Enter as many search terms as you\'d like. Health Data Select will return all datasets that contain any of the search terms(s) or phrase(s) (or a synonym) in a variable, variable code, or—where provided—the possible values. Results will be limited to datasets meeting any applied filters. Results are sorted in descending order so that the datasets containing variables for the most search terms are at the top. \n\nNote that availability of variables for a dataset may vary over time; presence of a variable in a particular release does not guarantee availability in other time periods.'
export const COMPARE_DATASETS_HINT = 'Select up to 3 datasets and click "Compare" to show a comparison of key characteristics of each dataset. Characteristics include the values for all filters to the left of this page, whether or not selected. Also provided are links to each dataset\'s summary page, which includes a brief description and key links for each dataset.'

export const FILTER_GROUPS: Record<'shortName', TFilterShortName>[][] = [
  [
    { shortName: 'host_organizations' }
  ],
  [
    { shortName: 'granularity' },
    { shortName: 'mechanism' },
    { shortName: 'population' },
    { shortName: 'record_level' },
    { shortName: 'weights' }
  ],
  [
    { shortName: 'time_design' },
    { shortName: 'periodicity' },
    { shortName: 'data_available_years' },
    { shortName: 'metadata_included_years' }
  ],
  [
    { shortName: 'geo_level' },
    { shortName: 'geo_variables' },
    { shortName: 'focus' }
  ],
  [
    { shortName: 'accessibility' }
  ]
]
export const FILTER_HINTS_MAP: Record<TFilterShortName, string> = {
  host_organizations: 'Host and other organizations: "Host" is the organization that maintains the website to which a user would go to obtain the dataset. "Other organizations" includes, for example, sponsors of the dataset and those responsible for data collection. Depending on the dataset, all functions may be performed either by a single or multiple organizations.',
  granularity: 'Granularity: Datasets can generally be classified as to whether they represent individual observations (microdata) or an aggregation of such observations (macrodata). Many datasets are available in both configurations. The metadata provided in Health Data Select is based on microdata unless the only publicly available version is as macrodata.',
  mechanism: 'Collection mechanism: The manner in which the data contained in the dataset were generated, such as survey or as a byproduct of administrative processes.',
  population: 'Population: The type of persons or entities included in the dataset.',
  record_level: 'Record level: The unit uniquely represented in each row of the dataset.',
  weights: 'Available weights: In sample-based datasets such as surveys, the presence of weights to enable the calculation of population estimates. Weights are typically assigned at the "record" level. Some datasets also include weights for various "non-record" levels of aggregation. For example, a dataset with weights provided at the record level of "person," may also have separate weights for the "family" level.',
  time_design: 'Time design: Time design describes the timing of the data collection (and in some cases the relationship between the timing and the target of collection). Where available, the values listed in Health Data Select are as reported by the dataset host. For example, the National Health Interview Survey (NHIS) is a “repeated cross-sectional” survey as it is administered to a different group of respondents each year.',
  periodicity: 'Current periodicity: The  interval at which the dataset is released. Example: Annual. "Current" refers to the fact that the availability of datasets may change over time. A dataset that was originally released every two years may "currently" be released annually.',
  data_available_years: 'Years data available from dataset host: The availability of the actual data maintained by the dataset host (as opposed to the metadata for certain years included in Health Data Select). For example, a dataset may have been in existence since 1990 with releases available every year.',
  metadata_included_years: 'Years of metadata included in Health Data Select: Health Data Select contains the most recent metadata available for each dataset at the time of its inclusion in the application. Metadata for subsequent iterations of each dataset, for prior years, and for new datasets are added on a regular basis.\n\nMetadata at the "dataset" level remain relatively constant over time. Metadata at the "variable" level within a dataset may also remain fairly constant but is more prone to change. That is, variables may be added, dropped or modified over time.',
  geo_level: 'Lowest geographic level: The most granular unit of geography provided in the dataset. For example, a national survey may contain variables for region and state. "State" would be the "lowest geographic level."',
  geo_variables: 'Geographic variables:  Datasets may contain variables representing multiple geographic levels. Geography is a common unit of analysis and as such, the available levels within a dataset can be an important differentiator.',
  focus: 'Content focus: Most of the datasets profiled in Health Data Select deal primarily with healthcare. However, there are many datasets that do not focus on health but do contain health-related variables. The "content focus" filter enables users to explore possibilities within "healthcare" and "non-healthcare" datasets.',
  accessibility: 'Accessibility: The manner in which the dataset is obtained (example: download, DVD); the existence of restrictions in obtaining the dataset; and the cost.'
}
