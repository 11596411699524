import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import ViewToggle from '../viewToggle/ViewToggle'
import { useAuthContext } from '../../../state/useAuth'
import Logout from '../logout/components/Logout'
import { ROUTES } from '../../../constants/routes'
import Tooltip from '../../shared/Tooltip'
import Feedback from '../feedback/components/Feedback'

const ContentWrapperStyled = styled.div<{ $isAdmin?: boolean }>`
  max-width: ${({ theme }) => theme.maxContentWidth};
  align-items: center;
  padding: 0 20px;
  margin: auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ $isAdmin }) => ($isAdmin ? 'repeat(3, 1fr)' : '1fr 1fr')};
  height: 100%;
`

const ContainerStyled = styled.div`
  height: 60px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.shadow};
  background-color: ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 10002;
`

const TitleStyled = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    cursor: pointer;
    position: relative;
    width: max-content;
`

const RoleNameStyled = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary};
`

const ViewToggleStyled = styled(ViewToggle)`
  justify-self: center;
`

const LogoutButtonStyled = styled(Logout)`
  justify-self: end;
`

const WrapStyled = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: 12px;  
`

const BetaTextStyled = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.white};
    padding: 4px 8px;
    background: ${({ theme }) => theme.colors.blue.primary};
    position: absolute;
    top: -10px;
    right: -46px;
    border-radius: 10px;
`

type TProps={
  handleRender:()=>void
}

const Header:FC<TProps> = ({ handleRender }) => {
  const isAdmin = useAuthContext()?.authData?.userRole === 'admin'

  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(ROUTES.DATASETS)
    handleRender()
  }

  return (
    <ContainerStyled>
      <ContentWrapperStyled $isAdmin={isAdmin}>
        <Tooltip placement="top" title="Clear search, return to HDS home">
          <TitleStyled onClick={handleNavigate}>
            Health Data Select
            {isAdmin && <RoleNameStyled> Admin</RoleNameStyled>}
            <BetaTextStyled>Beta</BetaTextStyled>
          </TitleStyled>
        </Tooltip>
        {isAdmin && <ViewToggleStyled />}
        <WrapStyled>
          <Feedback />
          <LogoutButtonStyled />
        </WrapStyled>
      </ContentWrapperStyled>
    </ContainerStyled>
  )
}

export default Header
